@import '../../../../Styles/cores.scss';

.filtrosDatagrid {
    color: black;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    height: 35px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1150px) {
        display: none;
    }
}

.tabela {
    width: 100%;
    min-height: 540px;
    height: 80vh;
    border-radius: 8px;

    @media (max-width: 1800px) {
        height: 75vh;
    }
}

.selectPlaca {
    width: 180px;
    height: 45px;
    font-size: 14px;
    padding-left: 10px;
    border-radius: 8px;


    &:disabled {
        background-color: rgb(117, 117, 117);
        color: white;
        cursor: not-allowed;
    }
}

.content-gridToolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 30px;
    background-color: transparent;
    margin-top: -10px;
    z-index: 1;
}

.content-operador-select-filtroData {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    gap: 6px;
    margin-top: 20px;
    margin-left: 50px;
    margin-bottom: -50px;

    @media (max-width: 1150px) {
        margin-bottom: 0px;
    }

}

.content-gridToolbar-intervalo-pesquisa {
    display: flex;
    align-items: center;
}

.content-gridToolbar-intervalo-info {
    color: $cor-secundaria;
    margin-bottom: 10px;
    margin-right: 5px;
}

.content-gridToolbar-intervalo-data {
    color: white;
    margin-bottom: 10px;
}