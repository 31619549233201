@import './../../Styles/cores.scss';

.search{
    display:flex;
    justify-content: flex-end;
    margin-right: 50px;
    margin-top: 5%;
    
}

.tabela {
    width: 100%;
    min-height: 400px;
    height: 64vh;
    border-radius: 8px;
    @media (max-width: 1800px) {
        height: 62vh;
        min-height: 200px;
    }
}

.searchInput{
    border-radius: 5px;
    outline: none;
    box-shadow: 2px 2px 10px 0px #00000040 inset;
    background: $cor-detalhes;
    color: $cor-primaria;
    padding-left: 10px;
    width: 230px;
    height: 35px;
    &::placeholder{
        color: $cor-placeholder;
    }
}