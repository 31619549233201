@import "../../Styles/cores.scss";
.carregando {
  margin-top: 13%;
  width: 150px;
}

.semValor {
  color: $cor-primaria;
  margin-top: 15%;
  @media (max-width: 450px) {
    margin-top: 100px;
  }
}

.agrupamento {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.tituloVideoAtual {
  color: $cor-primaria;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1%;
  text-transform: capitalize;
  &:first-of-type {
    color: #efb501;
    margin-right: 5px;
  }
}

.informacoes {
  color: $cor-primaria;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
}

.textoInformacoes {
  color: $cor-primaria;
  margin-top: 5px;
  width: 800px;
  @media (max-width: 850px) {
    width: 90vw;
  }
}

.borda {
  border: solid 1px $cor-secundaria;
  width: 120px;
}
