@import '../../Styles/cores.scss';
.navegacao {
    display: flex;
    align-items: center;
}

.pagina {
    font-size: 18px;
    color: $cor-primaria;
    text-transform: capitalize;
}

.icon {
    color: $cor-secundaria;
    margin-right: 15px;
    width: 30px;
    &:hover {
        color: $cor-hover-icones;
        cursor: pointer;
    }
}

.seta{
    width: 8px;
    margin-right: 5px;
}