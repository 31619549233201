@import "../../Styles/cores.scss";

.logo {
  margin-top: 6vh;
  width: 250px;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.input {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.buttonInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 43px;
  margin-bottom: 10%;
}

.HoraData {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: $cor-primaria;
  font-size: 17px;
  font-weight: bold;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.a {
  margin-top: 30px;
  text-decoration: none;
  color: $cor-button-popup;
  font-size: 15px;
  margin-top: -10px;
  cursor: pointer;
  transition: 300ms ease-in-out;
  &:hover {
    color: $cor-primaria;
  }
}

.espaco {
  display: flex;
  margin-left: 5px;
  margin-right: 10px;
}
