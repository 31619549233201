@import '../../../../Styles/cores.scss';

.texto {
    color: $cor-primaria;
    font-size: 17px;
    font-weight: 700;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px auto auto auto;
}

.input {
    display: flex;
    flex-direction: column;
}

.email {
    display: flex;
    justify-content: center;
    align-items: center;
}

.validar {
    display: flex;
    margin-left: 10px;
}

.enviar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 40px;
}

.voltar {
    cursor: pointer;
    transition: 500ms ease-in-out;

    &:hover {
        color: #ffff;
    }
}