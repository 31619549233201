@import '../../Styles/cores.scss';
.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 2px 10px 0px #00000040 inset;
    margin: 0 5% 0 5%;
    border-radius: 20px;
    padding: 1% 0 1% 0;
}

.agrupamento {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.titulo {
    color: $cor-primaria;
    font-size: 20px;
    font-weight: 600;
}

.selecionarVideo {
    display: flex;
    flex-direction: column;
    margin: 10px 0 20px 0;
}

.textArea {
    background: $cor-primaria;
    width: 99%;
    min-width: 100px;
    outline: none;
    height: 180px;
    border-radius: 5px;
    padding: 5px;
    font-size: 15px;
    padding-left: 10px;
    &::placeholder {
        font-size: 15px;
        color: $cor-placeholder;
    }
}

.selecionarArquivo {
    color: $cor-secundaria;
}

.labelInput {
    color: $cor-primaria;
    margin: 10px 0 10px 0;
}

.iconPopup {
    width: 70px;
    height: 70px;
}

.popup {
    width: 420px;
    border-radius: 10px;
}