$cor-primaria: #ffffff;
$cor-secundaria: #00a551;
$cor-button-popup: #277dd2;
$cor-button-popup-hover: #1e5d9b;
$cor-detalhes: #434141;
$cor-hover-sair: #c2c2c2;
$cor-hover-icones: #057d40;
$cor-background: #272727;
$cor-placeholder: #9c9c9c;
$cor-bg-header-accordion: #37373780;
$cor-bg-item-accordion: #27272757;
$cor-bg-item-accordion-hover: #2c2c2c;
