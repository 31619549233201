@import '../../Styles/cores.scss';
.opcoes {
    margin-top: 90px;
    margin-bottom: 20px;
}

.body {
    display: flex;
    flex-wrap: 4;
    flex-direction: column;
    margin-left: 2%;
    margin-top: 1.5%;
    margin-right: 2%;
}

.navbar {
    display: flex;
    flex-direction: column;
}

.tilte {
    color: #6F6F6F;
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 2px;
}

.DivInput {
    display: flex;
    align-items: center;
    width: 500px;
    height: 34px;
    background: $cor-primaria;
    border-radius: 5px;
    @media (max-width: 530px) {
        width: 90vw;
    }
}

.textInput {
    width: 240px;
    font-size: 0.9em;
    font-weight: 600;
    margin-left: 10px;
    color: $cor-background;
}

.popupTilte {
    font-size: 18px;
    font-weight: 500;
}

.iconPopup {
    width: 70px;
    height: 70px;
}

.popup {
    width: 450px;
}