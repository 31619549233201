@import "../../Styles/cores.scss";

.icon {
  color: $cor-secundaria;
  margin-left: 15px;
  width: 25px;
  &:last-of-type {
    width: 30px;
  }
  &:not(:first-of-type) {
    margin-left: 15px;
  }
  &:not(:first-of-type):hover {
    color: $cor-hover-icones;
    cursor: pointer;
  }
}

.btnPopup {
  width: 100px;
  border: none;
  outline: none;
}

.popupSair {
  width: 300px;
  border-radius: 15px;
}

.sair {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  width: 28px;
  color: $cor-primaria;
  margin-right: 2%;
  &:hover {
    color: $cor-hover-sair;
    cursor: pointer;
  }
}

.usuario {
  font-size: 18px;
  color: $cor-primaria;
  margin: 0px -1px -12px 0px;
  text-transform: capitalize;
}

.infosHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 40px 50px 50px 50px;
}
