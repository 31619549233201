@import "../../Styles/cores.scss";
.agrupamento {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}

.titulo {
  font-size: 24px;
  color: $cor-primaria;
  margin-bottom: 3%;
  font-weight: 500;
}

.contentTitle {
  display: flex;
  margin-bottom: 20px;
}

.mensagem {
  color: $cor-primaria;
  font-size: 17px;
  font-weight: 500;
}

.mensagemEBotao {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: -395px;
  @media (max-width: 910px) {
    margin: 0% 5%;
  }
  @media (max-width: 560px) {
    flex-direction: column;
  }
}
