@import '../../../Styles/cores.scss';
.agrupamento {
    margin-top: 3%;
}

.linha {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 5% 0.5% 5%;
    box-shadow: 2px 2px 10px 0px #00000040 inset;
    padding: 1%;
    border-radius: 10px;
    @media (max-width: 1200px) {
        height: 30px;
        margin: 1% 5%;
    }
    @media (max-width: 550px) {
        flex-direction: column;
        margin: 5%;
        height: 55px;
        align-items: flex-start;
    }
}

.icone {
    margin-right: 10px;
}

.texto {
    font-size: 15px;
    margin-left: 10px;
    text-transform: uppercase;
    color: $cor-primaria;
    font-weight: 700;
}

.iconeETexto {
    display: flex;
    align-items: center;
}

.ultimasLeitura {
    color: $cor-primaria;
    font-size: 15;
    font-weight: 600;
    margin-left: 5%;
    margin-bottom: 1%;
}

.dataHora {
    color: $cor-primaria;
    margin-left: 5px;
}