@import './../../Styles/cores.scss';

.div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.tilte {
    width: 663px;
    height: 59px;
    border-radius: 10px;
    background: $cor-secundaria;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 675px) {
        width: 98vw;
    }
}

.tilteText {
    font-size: 18px;
    font-weight: 700;
    color: $cor-primaria;
}

.subTilte {
    display: flex;
    align-items: center;
    width: 310px;
    height: 35px;
    background: $cor-detalhes;
    border-radius: 5px;
    box-shadow: 2px 2px 10px 0px #00000040 inset;
    color: $cor-primaria;
    font-size: 16px;
    font-weight: 700;
    padding-left: 10px;
    margin-top: 20px;

    @media (max-width: 350px) {
        width: 90vw;
        font-size: 14px;
    }
}