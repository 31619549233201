.mensagemTexto {
    width: 95vw;
    max-width: 600px;
    height: 300px;
    border-radius: 8px;
    outline: none;
    font-size: 1em;
    padding: 10px;
    @media (max-width: 650px) {
      line-height: 30px;
      padding: 0px;
    }
  }