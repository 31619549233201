@import '../../../Styles/cores.scss';
.nav {
    list-style-type: none;
    border-radius: 10px;
    background: $cor-bg-header-accordion;
    margin: 0;
    padding: 0;
    width: 300px;
    overflow: hidden;
    margin-top: 1%;
}

.navItem {
    font-size: 20px;
    background: $cor-detalhes;
    border-bottom: 10px solid $cor-background;
    &:last-child {
        border-bottom: none;
    }
}

.navLink,
.navSubmenuLink {
    text-decoration: none;
    font-size: 15px;
    font-weight: 700;
    padding: 16px 20px;
    display: block;
    color: $cor-primaria;
}

.navSubmenu {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s;
    background: $cor-bg-item-accordion;
    border-left: solid 1px $cor-secundaria;
    &:target {
        max-height: 10rem;
    }
}

.navSubmenuLink {
    font-size: 16px;
    background: transparent;
    transition: 0.2s ease-in;
    &:hover {
        background: $cor-bg-item-accordion-hover;
    }
}