@import "../../Styles/cores.scss";

.inputInfos {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data {
  color: $cor-primaria;
  margin-left: 5px;
  font-weight: 600;
}

.tilte {
  display: flex;
  justify-content: center;
  color: $cor-primaria;
  font-size: 20px;
  margin-top: 5%;
}

.all {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
  width: 100%;
}

.legenda {
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: $cor-primaria;
  margin-top: 3%;
  margin-bottom: 1%;
}

.select {
  width: 12%;
  font-size: 0.9em;
  padding-left: 10px;
  margin-left: 5px;

  @media (max-width: 590px) {
    margin-left: 0px;
  }

  @media (max-width: 350px) {
    margin-bottom: 5px;
  }
}

.cronometro {
  display: flex;
  align-items: center;
}

.primeiraLista {
  height: 30px;
  background: $cor-detalhes;
  border-radius: 5px;
  margin-top: 1%;
  color: $cor-primaria;
}

.tiltePrimeiraLista {
  padding: 10px;

  &:first-of-type {
    border-radius: 5px 0 0 0;
  }

  &:nth-of-type(3) {
    border-radius: 0 5px 0 0;
  }
}

.linhas {
  max-width: 970px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $cor-background;
  background: $cor-primaria;
  padding: 10px;
}

.situacoes {
  display: flex;
  background: $cor-primaria;
  padding: 10px;
  font-size: 16px;
  input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  p {
    margin-right: 5px;
  }
}

.tabela {
  width: auto;
  max-width: 100%;
  margin-bottom: 10px;
}

.iconPopup {
  width: 70px;
  height: 70px;
}

.popup {
  width: 420px;
  border-radius: 10px;
}

.popupTime {
  width: 320px;
  border-radius: 10px;
}

.carregando {
  margin-top: 1%;
  width: 80px;
}
