@import './../../Styles/cores.scss';
.login {
    width: 300px;
    height: 45px;
    border-radius: 4px;
    background-color: $cor-primaria;
    font-size: 18px;
    font-weight: 600;
    background: #272727;
    z-index: 0;
}

.confirmarEmail {
    width: 450px;
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 16px;
    padding-left: 15px;
    outline: none;
}

.confirmarSenha {
    width: 450px;
    height: 40px;
}

.vassoura {
    width: 323px;
    height: 50px;
    background: $cor-primaria;
    border-radius: 5px;
    outline: none;
    margin-top: 15px;
    margin-right: 15px;
    font-size: 17px;
    font-weight: 500;
    padding-left: 10px;
    @media (max-width: 350px) {
        width: 80vw;
    }
    &::placeholder {
        color: $cor-placeholder;
    }
    &:disabled {
        font-weight: 600;
        color: $cor-primaria;
        background: $cor-detalhes;
    }
}

.diarioBordo {
    width: 400px;
    height: 34px;
    outline: none;
    border-radius: 5px;
    color: $cor-background;
    font-size: 14px;
    &::placeholder {
        color: $cor-background;
        font-size: 14px
    }
}

.input-date {
    width: 160px;
    font-size: 1em;
    outline: none;
    padding: 4px;
    border: 1px solid rgb(188, 187, 187);
    border-radius: 8px;
    color: $cor-detalhes;
    &::-webkit-calendar-picker-indicator {
        font-size: 1.2em;
    }
}

.checkList {
    width: 12%;
    height: 38px;
    outline: none;
    border-radius: 5px;
    font-size: 15px;
    padding-left: 10px;
    margin-left: 1%;
    text-transform: capitalize;
    &::placeholder {
        font-size: 15px;
        color: $cor-placeholder;
    }
}

.operadorListagemCheckList {
    width: 180px;
    height: 45px;
    padding-left: 10px;
    outline: none;
    border-radius: 8px;
    text-transform: capitalize;
    z-index: 3;
    &::placeholder {
        font-size: 15px;
        color: $cor-placeholder;
    }
}

.checkListPlaca {
    width: 12%;
    height: 38px;
    outline: none;
    border-radius: 5px;
    font-size: 15px;
    padding-left: 10px;
    margin-left: 1%;
    text-transform: uppercase;
    &::placeholder {
        font-size: 15px;
        color: $cor-placeholder;
    }
}

.uploadVideo {
    width: 100%;
    height: 38px;
    outline: none;
    border-radius: 5px;
    font-size: 15px;
    padding-left: 10px;
    &::placeholder {
        font-size: 15px;
        color: $cor-placeholder;
    }
}

.uploadVideoSelecionarArquivo {
    color: $cor-secundaria;
    
   @media (max-width: 420px) {
        width: 110px;
    }
}

.mensagemTexto-title {
    width: 44%;
    min-width: 180px;
    height: 38px;
    outline: none;
    border-radius: 5px;
    font-size: 15px;
    padding-left: 10px;
    margin-left: 1%;
    &::placeholder {
        font-size: 15px;
        color: $cor-placeholder;
    }
}
