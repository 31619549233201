@import '../../../../Styles/cores.scss';

.code {
    text-align: center;
    margin-top: 50px;
}

.input {
    border-radius: 5px;
    border-bottom: 1px solid black;
    margin: 0 4px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    width: 50px;
    height: 60px;
    outline: none;
}

.texto {
    color: $cor-primaria;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 40px;
}

.textoSenha{
    display: flex;
    justify-content: flex-end;
    color: $cor-primaria;
    font-size: 17px;
    font-weight: 700;
    margin-top: 60px;
    margin-bottom: 10px;
    margin-right: 170px;
}

.textoSenhaConfirmar{
    color: $cor-primaria;
    font-size: 17px;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-right: 190px;
}

.divSenha{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aviso{
    color: #db6868c1;
    font-size: 12px;
    font-weight: 400;
    margin-top: -15px;
    margin-left: 110px;
}