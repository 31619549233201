@import "../../Styles/cores.scss";

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15%;
}
.img {
  width: 420px;
  &::selection {
    outline: none;
  }
}

.tilte {
  color: $cor-primaria;
  font-size: 22px;
  margin-bottom: 10px;
}

.subtilte {
  color: $cor-primaria;
  font-size: 14px;
  margin-bottom: 50px;
}

.a {
  color: $cor-button-popup;
  text-decoration: none;
  margin-top: 50px;
  &:hover {
    color: $cor-primaria;
  }
}
