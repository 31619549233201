@import '../../../../Styles/cores.scss';

.content-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80dvh;
    height: 80vh;
}

.img-loading {
    width: 150px;
}

.content-informacoes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 60px;
    margin: 10px 0;
}

.content-informacoes-icon-title-value {
    display: flex;
    align-items: center;
    gap: 4px;
}

.content-informacoes-title {
    color: white;
    font-size: 12px;
}

.content-informacoes-value {
    color: white;
    font-size: 22px;
}

.content-export-text-icon-pdf {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 60px;
    margin-top: 26px;
    gap: 4px;
}

.content-export-text {
    color: white;
    font-weight: bold;
}

.content-export-icon {
    color: white;
    &:hover {
        color: $cor-secundaria;
    }
}