@import '../../Styles/cores.scss';

.search {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
    margin-top: 1%;
}

.searchInput {
    border-radius: 5px;
    outline: none;
    box-shadow: 2px 2px 10px 0px #00000040 inset;
    background: $cor-detalhes;
    color: $cor-primaria;
    padding-left: 10px;
    width: 230px;
    height: 35px;

    &::placeholder {
        color: $cor-placeholder;
    }
}

.exportar {
    float: right;
    margin-right: 50px;
    margin-top: 40px;
    cursor: pointer;
}

.filtrosDatagrid {
    color: black;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    height: 35px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    margin: 8px;

    @media (min-width: 500px) and (max-width: 985px) {
        margin-left: 0px;
    }

    @media (max-width: 500px) {
        display: none;
    }
}

.tabela {
    width: 100%;
    min-height: 400px;
    height: 80vh;
    border-radius: 8px;

    @media (max-width: 1800px) {
        height: 75vh;
    }
}

.selecionarTipoRelatorio {
    font-size: 14px;
    padding-left: 10px;
    height: 45px;
    border-radius: 8px;

    @media (max-width: 750px) {
        margin-bottom: 10px;
    }

    &:disabled {
        background-color: rgb(117, 117, 117);
        color: white;
        cursor: not-allowed;
    }
}

.div-somente-inicio-fim {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.p-somente-inicio-fim {
    color: $cor-primaria;
    margin-right: 10px;
}

.div-placa {
    display: flex;
    align-items: center;
}

.placa-icon {
    margin-right: 6px;
}

.content-placa {
    display: flex;
    flex-direction: column;
    color: white;
}

.text-placa {
    font-size: 0.9em;
}

.placa {
    font-size: 1em;
    font-weight: 600;
}

.div-select-relatorio {
    margin: 0 8px 0 0;
}

.div-filtros {
    margin: 0 0 0 8px;
}