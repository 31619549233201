@import "../../Styles/cores.scss";

.login {
  width: 350px;
  height: 45px;
  border-radius: 4px;
  background: $cor-primaria;
  transition: 500ms ease-in-out;
  border-color: $cor-primaria;
  cursor: pointer;
  color: $cor-button-popup;
  font-size: 15px;
  font-weight: bold;

  &:hover {
    color: $cor-primaria;
    background: $cor-secundaria;
  }
}

.validarEmail {
  width: 120px;
  height: 35px;
  border-radius: 4px;
  background: $cor-secundaria;
  transition: 500ms ease-in-out;
  border-color: $cor-primaria;
  cursor: pointer;
  color: $cor-primaria;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;

  &:hover {
    color: $cor-button-popup;
    background: $cor-primaria;
  }

  &:disabled {
    background: $cor-detalhes;
    color: $cor-hover-sair;
  }
}

.confirmarEmail {
  width: 180px;
  height: 35px;
  border-radius: 4px;
  background: $cor-secundaria;
  transition: 500ms ease-in-out;
  border-color: $cor-primaria;
  cursor: pointer;
  color: $cor-primaria;
  font-size: 15px;
  font-weight: bold;
  margin-top: 30px;

  &:disabled {
    background: $cor-detalhes;
    color: $cor-hover-sair;
  }
}

.EnviarVassouras {
  width: 250px;
  height: 40px;
  border-radius: 5px;
  margin-top: 60px;
  background: $cor-primaria;
  color: $cor-button-popup;
  font-size: 15px;
  font-weight: bold;
  transition: 500ms ease-in-out;
  cursor: pointer;

  &:hover {
    color: $cor-primaria;
    background: $cor-secundaria;
  }
}

.EnviarMensagem {
  width: 200px;
  height: 40px;
  border-radius: 5px;
  margin-top: 20px;
  background: $cor-primaria;
  color: $cor-button-popup;
  font-size: 15px;
  font-weight: bold;
  transition: 500ms ease-in-out;
  cursor: pointer;

  &:hover {
    color: $cor-primaria;
    background: $cor-secundaria;
  }
}

.diarioBordo {
  width: calc(360px - 11.5px);
  height: 58px;
  border-radius: 5px;
  background-color: $cor-primaria;
  margin-right: 20px;
  margin-top: 15px;
  color: $cor-button-popup;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  transition: 500ms ease-in-out;

  @media (max-width: 400px) {
    width: 87vw;
  }

  &:hover {
    color: $cor-primaria;
    background: $cor-secundaria;
  }
}

.videoInformativo {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  background: $cor-primaria;
  color: $cor-button-popup;
  font-size: 14px;
  font-weight: bold;
  transition: 500ms ease-in-out;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    color: $cor-primaria;
    background: $cor-secundaria;
  }

  &:disabled {
    background: $cor-detalhes;
    color: $cor-hover-sair;
  }
}

.checkList {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  background: $cor-primaria;
  color: $cor-button-popup;
  font-size: 14px;
  font-weight: bold;
  transition: 500ms ease-in-out;
  margin-top: 3%;
  margin-bottom: 3%;
  cursor: pointer;

  &:hover {
    color: $cor-primaria;
    background: $cor-secundaria;
  }

  &:disabled {
    background: $cor-detalhes;
    color: $cor-hover-sair;
  }
}

.IniciarCheckList {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  background: $cor-primaria;
  color: $cor-button-popup;
  font-size: 14px;
  font-weight: bold;
  transition: 500ms ease-in-out;
  cursor: pointer;
  margin-top: 5%;

  &:hover {
    color: $cor-primaria;
    background: $cor-secundaria;
  }
}

.uploadVideo {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  background: $cor-primaria;
  color: $cor-button-popup;
  font-size: 14px;
  font-weight: bold;
  transition: 500ms ease-in-out;
  margin-top: 3%;
  margin-bottom: 3%;
  cursor: pointer;

  &:hover {
    color: $cor-primaria;
    background: $cor-secundaria;
  }

  &:disabled {
    background: $cor-detalhes;
    color: $cor-hover-sair;
  }
}

.btn-filtrar {
  width: 100px;
  min-width: 64px;
  height: 35px;
  margin-left: 3%;
  margin-right: 3%;
  font-size: 0.9em;
  background: $cor-button-popup;
  border-radius: 8px;
  color: $cor-primaria;
  cursor: pointer;

  &:hover {
    background: $cor-button-popup-hover;
  }
}

.btn-Selecionar {
  width: 100px;
  min-width: 64px;
  height: 39px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 0.9em;
  font-weight: bold;
  background: $cor-button-popup;
  border-radius: 4px;
  color: $cor-primaria;
  cursor: pointer;

  &:hover {
    background: $cor-button-popup-hover;
  }

  @media (max-width: 590px) {
    margin-top: 30px;
  }
}

.btn-enviar-comandos {
  width: 240px;
  min-width: 64px;
  height: 39px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 0.9em;
  font-weight: bold;
  background: $cor-secundaria;
  border-radius: 4px;
  color: $cor-primaria;
  cursor: pointer;
  margin-top: 50px;
  margin-left: 5px;

  &:hover {
    background: $cor-hover-icones;
  }

  &:disabled {
    background: $cor-detalhes;
    color: $cor-hover-sair;
  }
}