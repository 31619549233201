@import "../../Styles/cores.scss";

.content-mensagem {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 590px;
    min-height: 40px;
    border-radius: 8px;
    background-color: $cor-primaria;
    margin: 2px 10px 10px 10px;
    overflow: hidden;
    padding: 5px;
}

.content-info {
    display: flex;
    align-items: center;
}

.envio {
    margin-right: 40px;
    display: flex;
    justify-content: flex-end;
    color: $cor-primaria;
    font-size: 14px;
}

.content-main {
    margin-bottom: 30px;
}

.content-recebido{
    color: $cor-primaria;
    font-size: 13px;
    margin: 10px 10px 0 10px;
}