@import '../../../../Styles/cores.scss';
.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px auto auto auto;
}

.texto {
    color: $cor-primaria;
    font-size: 17px;
    font-weight: 700;
}

.check {
    margin-top: 20px;
}

.voltar {
    color: #315DD2;
    font-size: 17px;
    font-weight: 500;
    margin-top: 50px;
}