@import "../../Styles/cores.scss";

.main-container {
    display: flex;
    flex-direction: column;

    @media (max-width: 600px) {
        justify-content: center;
        align-items: center;
    }
}

.container-input {
    margin-bottom: 20px;
}

.historico {
    width: 100%;
    max-width: 600px;
    height: 480px;
    background-color: $cor-bg-item-accordion;
    border-radius: 8px;
    overflow-y: auto;

    @media (max-width: 1220px) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.historico-title {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: $cor-primaria;
}

.main {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    flex-wrap: wrap;
}

.select {
    width: 55%;
    min-width: 297px;
    font-size: 0.9em;
}

.main-content-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}