* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Lato", "Arial", "sans-serif";
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #898a8d;
  border-radius: 20px;
}

body {
  background: #272727;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:root {
  --cor-primaria: #ffffff;
  --cor-secundaria: #00a551;
  --cor-button-popup: #277dd2;
  --cor-detalhes: #434141;
  --cor-hover-sair: #c2c2c2;
  --cor-hover-icones: #057d40;
  --cor-background: #272727;
  --cor-placeholder: #9c9c9c;
}
