@import "../../../../Styles/cores.scss";

.agrupamento {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 3%;
  margin-top: 1%;
}

.img {
  margin-right: 10px;
  margin-left: 10px;
}

.card {
  display: flex;
  background: $cor-primaria;
  border-radius: 15px;
  width: 23%;
  min-width: 300px;
  height: 100px;
  margin: 1%;
  transition: 200ms ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(0.98);
  }

  @media (max-width: 700px) {
    height: 80px;
  }
}

.conteudo {
  display: flex;
  align-items: center;
}

.texto {
  font-size: 18px;
  font-weight: 600;
}

.icone {
  color: $cor-secundaria;
  padding: 10px;
}

.select {
  width: 50%;
  min-width: 240px;
  max-width: 280px;
  font-size: 0.9em;
  margin-left: 5px;
  margin-right: 5px;

  @media (max-width: 590px) {
    margin-left: 0px;
  }

  @media (max-width: 350px) {
    margin-bottom: 5px;
  }
}

.main-select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 590px) {
    justify-content: center;
  }
}

.title-select {
  color: $cor-primaria;
  margin-left: 60px;
  font-weight: bold;

  @media (max-width: 590px) {
    margin-left: 0px;
  }
}

.content-select {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 350px) {
    justify-content: center;
  }
}

.red {
  color: red;
}

.green {
  color: green;
}
