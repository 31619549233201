@import "./../../Styles/cores.scss";

.logo {
  margin-top: 100px;
  width: 220px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.efeito {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  margin: 0px 15px;
  background: $cor-bg-item-accordion-hover;
  box-shadow: 2px 2px 10px 0px #00000040 inset;
  border-radius: 10px;
  margin-top: 5%;
}

.texto {
  font-size: 20px;
  font-weight: 600;
  color: $cor-primaria;
  display: flex;
  align-items: center;
}

.HoraData {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: $cor-primaria;
  font-size: 17px;
  font-weight: bold;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.espaco {
  display: flex;
  margin-left: 5px;
  margin-right: 10px;
}
