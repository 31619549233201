@import './../../Styles/cores.scss';

.date {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.text {
    margin-left: 5%;
    margin-right: 2%;
    font-size: 0.9em;
    color: $cor-background;

    &:last-of-type {
        margin-left: 2%;
    }
}

.content {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 400px;

    @media (max-width: 500px) {
        max-width: 300px;
    }
}