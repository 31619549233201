@import '../../../../Styles/cores.scss';

.usuario{
    width: 300px;
    height: 45px;
    background-color: transparent;
    font-size: 17px;
    font-weight:lighter ;
    color: $cor-primaria;
    outline: none;
    margin-left: 15px;
    margin-top: 5px;
    &::placeholder{
        color: $cor-primaria;
        margin-right: 10px;
    }
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: white;
    }
}


.divUsuario{
    display: flex;
    align-items: center;
    width: 350px;
    height: 45px;
    border: 1px solid $cor-primaria;
    border-radius: 4px;
    margin-bottom: 20px;
}

.icon {
    color: $cor-primaria;
    margin-left: 5px;
}
