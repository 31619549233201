@import '../../../../Styles/cores.scss';

.content-verificaoes-check-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    gap: 30px;
    padding: 0 60px;
    margin: 26px 0;
}

.content-verificaoes-title {
    color: white;
    margin-bottom: 6px;
}

.content-verificacoes-text-situacao {
    display: flex;
    gap: 4px;
    margin: 4px 0;
}

.content-verificacoes-text {
    width: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 4px;
}

.content-verificacoes-situacao {
    display: flex;
    align-items: center;
    padding: 10px;
    color: white;
    font-weight: bolder;
    border-radius: 4px;
    text-transform: capitalize;
}