@import '../../Styles/cores.scss';


.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.selectComandos {
    width: 100vw;
    min-width: 270px;
    max-width: 568px;
    font-size: 0.9em;
    margin-left: 2px;

    @media (max-width: 590px) {
        margin-bottom: 5px;
    }
}

.select {
    width: 90vw;
    min-width: 270px;
    max-width: 465px;
    font-size: 0.9em;
    margin-left: 5px;
    margin-right: 5px;

    @media (max-width: 590px) {
        margin-bottom: 5px;
        max-width: 575px;
    }
}

.main-select {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 590px) {
        justify-content: center;
    }
}

.main-bottom {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.background-title {
    display: flex;
    align-items: center;
    background-color: $cor-primaria;
    width: 80vw;
    min-width: 270px;
    max-width: 520px;
    height: 32px;
    border-radius: 5px;
    margin-left: 60px;
    margin-top: 30px;
    padding: 2px;

    @media (max-width: 590px) {
        justify-items: center;
        text-align: center;
        margin-left: 30px;
        padding: 5px;
    }
}

.title {
    color: $cor-background;
    margin-left: 10px;
    font-weight: bold;
}

.title-select {
    color: $cor-primaria;
    font-weight: bold;
    margin-bottom: 5px;

    @media (max-width: 350px) {
        margin-left: 40px;
    }
}

.title-select-comandos {
    color: $cor-primaria;
    font-weight: bold;
    margin-bottom: 5px;
}

.content-select {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 590px) {
        align-items: center;
        flex-direction: column;
    }
}

.red {
    color: red;
}

.green {
    color: green;
}